<template>
  <div>
    <!-- BREADCRUMB -->
    <section class="py-9 bg-parallax" style="background-image: url(assets/img/background/background-img-1.jpg);">
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">【爸媽請進】練習單</h1>
            <p class="text-white font-size-16">根據親子天下調查，有高達95%認為應該從小培養孩子的理財觀念，但是只有21%的家長可以回答孩子的理財問題，如果你想培養孩子的金融素養，歡迎你下載分齡的理財練習單，我們陪孩子 一起學理財。</p>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item ">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">爸媽請進</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section >
      <div class="container py-6 py-md-8">
        <div class="row">
          <div v-for="(item, index) in learningSheetData" :key="index" :id="item.id" class="col-md-6">
            <div class="media media-events-list" :class="item.theme">
              <a class="media-img" href="">
                <img :src="item.banner" alt="events-image">
                <div class="media-img-overlay p-4">
                  <div class="media-icon-large rounded shadow-sm mr-4" :class="item.theme">
                    <i aria-hidden="true" :class="item.icon"></i>
                  </div>
                </div>
              </a>
              <div class="media-body">
                <h3 class="media-heading text-white text-capitalize d-block text-truncate text-center mb-4">{{ item.title }}</h3>
                <div v-for="sheet in item.sheetList" :key="sheet.id">
                  <ul class="list-unstyled mb-2">
                    <li class="text-white mb-1 text-center font-size-20">
                      <i class="fa fa-hand-o-right mr-2" aria-hidden="true"></i>{{ `level ${sheet.level}` }}<i class="fa fa-hand-o-left ml-2" aria-hidden="true"></i>
                    </li>
                  </ul>
                  <div class="ml-auto text-center mb-4">
                    <router-link :to="`/learning-sheet/download/${sheet.id}`" class="btn btn-white btn-sm text-uppercase text-hover-success mr-1">線上瀏覽</router-link>
                    <button @click="fileDownloader(sheet.pdf, 'pdf', sheet.fileName)" target="_blank" class="btn btn-white btn-sm text-uppercase text-hover-success">練習單下載</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import tokenGenerator from '@/tokenGenerator.js'
import fileDownloader from '@/fileDownloader.js'
import richkidConfig from '@/assets/json/richkid.config.json'
export default {
  data () {
    return {
      learningSheetData: [
        {
          title: '理財幼幼級練習單',
          icon: 'fa fa-leaf',
          theme: 'bg-success',
          banner: ['/assets/img/events/events-img1.jpg'],
          sheetList: [],
          id: 'levelA'
        },
        {
          title: '理財國小級練習單',
          icon: 'fa fa-futbol-o',
          theme: 'bg-info',
          banner: ['/assets/img/events/events-img2.jpg'],
          sheetList: [],
          id: 'levelB'
        },
        {
          title: '理財國中級練習單',
          icon: 'fa fa-gamepad',
          theme: 'bg-danger',
          banner: ['/assets/img/events/events-img3.jpg'],
          sheetList: [],
          id: 'levelC'
        },
        {
          title: '理財高中級練習單',
          icon: 'fa fa-graduation-cap',
          theme: 'bg-warning',
          banner: ['/assets/img/events/events-img4.jpg'],
          sheetList: [],
          id: 'levelD'
        },
        {
          title: '108課綱',
          icon: 'fa fa-university',
          theme: 'bg-purple',
          banner: ['/assets/img/events/events-img2.jpg'],
          sheetList: [],
          id: 'levelE'
        }
      ]
    }
  },
  computed: {
    fileDownloader () {
      return fileDownloader
    },
    VUE_APP_APIPATH () {
      return process.env.VUE_APP_APIPATH
    },
    richkidGradeData () {
      return richkidConfig.data.grade
    }
  },
  created () {
    const vm = this
    vm.getData()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getData () {
      const vm = this
      const api = `${vm.VUE_APP_APIPATH}/iPlay/sheet/list`

      vm.axios.post(api, {
        token: tokenGenerator()
      })
        .then((res) => {
          res.data.content.sort((a, b) => a.level - b.level).forEach(item => {
            const gradeName = this.richkidGradeData[item.grade].title
            item.pdf = item.pdf.replace('https://gcs.moneybar.com.tw/', '')
            item.fileName = `${dayjs().format('YYYY-MM-DD')}_練習單_${gradeName}_level${item.level}.pdf`
            const i = item.grade - 1
            this.learningSheetData[i].sheetList.push(item)
          })
          vm.$nextTick(() => {
            const { anchor } = this.$route.query
            if (anchor) {
              const target = document.querySelector(`#${anchor}`)
              const top = target.getBoundingClientRect().top
              const navbarHeight = document.querySelector('nav.navbar').getBoundingClientRect().height
              console.log(top - navbarHeight)
              window.scrollTo(0, top - navbarHeight)
            }
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/kidz.css';
</style>
